import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb capell de fins a 12 cm de diàmetre, primer convex amb el marge enrotllat i, amb el temps, aplanat, enfonsat al centre i amb el marge obert. Presenta una cutícula molt zonada de color groc taronjós, amb cercles concèntrics més foscos i rogencs. El peu és del mateix color que el capell, curt i cilíndric. Les làmines, nombroses, de blanquinoses a ocres, atapeïdes, d’adnades a adnado-decurrents. Les espores són de color crema blanquinós en massa, el·líptiques a ovoïdals, amb berrugues, de 8-9 x 6-7,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      